import { AppState } from '@redux/types';

import {
  PurchasedSubscription,
  UpdateProfileWithCountry,
} from '@redux/reducers/profile/types';
import { _getCountryById } from 'redux/reducers/countries/selectors';

import { Account } from '@shared/interfaces/account';
import { Currency } from '@shared/interfaces/currency';
import { Deletion } from '@shared/interfaces/deletion';
import { SubscriptionBuy } from '@shared/interfaces/subscription-buy';
import { _getAppById } from '../applications/selectors';
import _groupBy from 'lodash/groupBy';
import { createSelector } from 'reselect';

import { getStoreLink } from '@shared/subscription/functions';
import moment from 'moment';

const getFullName = ({ firstName = '', lastName = '' }) =>
  `${firstName} ${lastName}`.trim();

export function _getCurrency(state: AppState): Currency {
  return state.currency.currentCurrency;
}

const _getProfileBase = state => state.profile;

export const _getProfile = createSelector(_getProfileBase, profile => ({
  ...profile,
  fullName: getFullName(profile),
}));

export function _getPurchasedSubscriptions(
  state: AppState
): PurchasedSubscription[] {
  const profile = _getProfile(state);

  return profile.subscriptionBuys.reduce((acc, subscription) => {
    const application = _getAppById(+subscription.appId, state);
    const originalSubscription = application.subscriptions.find(
      sub => sub.bundle === subscription.bundle
    );
    const hasExpired = moment.utc().isAfter(moment.utc(subscription.expire));
    const filteredBillingHistory = profile.billingHistory.filter(
      history => history.bundle === subscription.bundle
    );

    return [
      ...acc,
      {
        hasExpired,
        subscription,
        originalSubscription,
        billingHistory: filteredBillingHistory,
        appName: application.name,
      },
    ];
  }, []);
}

export function _getPurchasedPlatforms(state: AppState) {
  const profile = _getProfile(state);

  const groupedPlatforms = _groupBy(profile.platformBuys, 'appId');
  const formattedApplications: Record<string, string> = Object.keys(
    groupedPlatforms
  ).reduce((acc, appId) => {
    const application = _getAppById(+appId, state);

    if (application) {
      return { ...acc, [appId]: application.name };
    }

    return { ...acc };
  }, {});

  return {
    groupedPlatforms,
    formattedApplications,
  };
}

export function _updateProfileCountry(
  state: AppState,
  profile: Account
): UpdateProfileWithCountry {
  return {
    ...profile,
    updatedCountry: _getCountryById(state, profile.country),
  };
}

export function _getActiveSubs(state) {
  const subscription = _getPurchasedSubscriptions(state);

  return subscription.map(s => {
    const { store } = s?.subscription;

    return store;
  });
}

export function _getStoreLink(state: AppState) {
  const subscription = _getPurchasedSubscriptions(state);

  const data: Partial<SubscriptionBuy>[] = subscription.map(s => {
    const { bundle, store, status } = s?.subscription;

    return { bundle, store, status };
  });

  return getStoreLink(data);
}

export function _getDeletionState(state: AppState): Deletion {
  const profile = _getProfile(state);

  return profile.deletion;
}
