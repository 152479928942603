import {
  CountriesAction,
  CountriesState,
} from '@redux/reducers/countries/types';
import { CountriesActionType } from '@redux/reducers/countries/enum';

function countriesReducer(
  state: CountriesState[] = [],
  action: CountriesAction
): CountriesState[] {
  switch (action.type) {
    case CountriesActionType.Set:
      return action.payload;
    default:
      return state;
  }
}

export default countriesReducer;
