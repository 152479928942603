import { AddToCart, PaymentMethod } from '@utils/interfaces/google-analytics-4';

import {
  GmtEvents,
  PurchaseData,
  PurchaseDataProducts,
} from '@utils/interfaces/google-tag-manager';
import isServer from '@utils/isServer';

import { Bundle } from '@shared/enums/bundle';
import { Currencies } from '@shared/enums/currency';
import { Order } from '@shared/interfaces/order';
import { PurchaseStatisticsProduct } from '@shared/interfaces/analytics';
import { _getProductType } from '@shared/basket/functions';
import { _getSeparatedNames } from '@shared/product/functions';
import { readCookie } from './cookiesManager';

const GTM_EVENTS: GmtEvents = {
  pageView: 'PageView',
  addToBag: 'AddToBag',
  purchase: 'Purchase',
  defaultEvent: 'defaultEvent',
  checkout: 'checkout', // enhanced ecommerce
  click: 'productClick', // enhanced ecommerce
  addToCart: 'addToCart', // enhanced ecommerce
  checkoutOption: 'checkoutOption', // enhanced ecommerce
  defaultISO: Currencies.USD,
  subHeader: {
    eventCategory: 'SubHeader',
    eventAction: 'Clicks',
    eventLabel: {
      login: 'Login',
      logout: 'Logout',
    },
  },
  licensePage: {
    [Bundle.Student]: {
      eventCategory: 'StudentBuyPage',
      eventAction: 'Clicks',
      eventLabel: {
        playVideo: 'PlayVideo',
        buyNow: 'BuyNow',
        comparePlans: 'ComparePlans',
        arrowRight: 'Navigate to Professional',
        arrowLeft: 'Navigate to Professional',
      },
    },
    [Bundle.Professional]: {
      eventCategory: 'ProfessionalBuyPage',
      eventAction: 'Clicks',
      eventLabel: {
        playVideo: 'PlayVideo',
        buyNow: 'BuyNow',
        comparePlans: 'ComparePlans',
        arrowRight: 'Navigate to Student',
        arrowLeft: 'Navigate to Student',
      },
    },
  },
  pricingPage: {
    eventCategory: 'PricingPage',
    eventAction: 'Clicks',
    eventLabel: {
      tiffStartToday: 'Tiff-Start-today',
      tiffTFT: 'Tiff-Take-the-Free-Trial',
      buyLicenseStartToday: 'BuyLicense-Start-today',
      buyNowStudent: 'BuyNow-StudentPlus',
      buyNowProfessional: 'BuyNow-Professional',
      upgradeProfessional: 'Upgrade-Professional',
      requestDemoInstitutional: 'RequestDemo-Institutional',
      ipadCH: 'iPad of Complete Heart',
      macCH: 'Mac of Complete Heart',
      windowsCH: 'Windows of Complete Heart',
    },
  },
  confirmationPage: {
    eventCategory: 'ConfirmationPage',
    eventAction: 'Clicks',
    eventLabel: {
      continue: 'Continue',
      setupGuid: 'SetupGuid',
      take2minute: 'Take2minute',
    },
  },
  accountPage: {
    eventCategory: 'AccountPage',
    eventAction: 'Clicks',
    eventLabel: {
      turnOnAutoRenewSwitch: 'Turn on auto-renew switch',
      turnOffAutoRenewSwitch: 'Turn off auto-renew switch',
      turnOffAutoRenewWarning:
        'Select "Turn off auto-renew" in the alert warning',
      getOfferWarning: 'Select "Get offer" in the alert warning',
    },
  },
};

const GTM_CONTENT_TYPES = {
  CONTENT: 'content',
  SUBSCRIPTION: 'subscription',
};

const clearEcommerceObject = () => {
  if (!isServer() && window['dataLayer']) {
    window['dataLayer'].push({ ecommerce: null });
  }
};

const trackStatistics = (event: string, data = {}) => {
  if (
    !isServer() &&
    window['dataLayer'] &&
    JSON.parse(readCookie('showAnalyticsCookies'))
  ) {
    window['dataLayer'].push({
      event,
      ...data,
    });
  }
};

const getPurchasedProducts = (products = []) => {
  if (products.length) {
    const names = _getSeparatedNames(products);

    return `Complete Anatomy (${names})`;
  }
};

const getGTMContentType = product => {
  const productType = _getProductType(product);

  switch (true) {
    case productType === 'subscription':
      return GTM_CONTENT_TYPES.SUBSCRIPTION;
    default:
      return GTM_CONTENT_TYPES.CONTENT;
  }
};

export const getContentTypes = products => {
  const contentTypes = Object.keys(GTM_CONTENT_TYPES).map(type => ({
    contents: products.filter(
      product => getGTMContentType(product) === GTM_CONTENT_TYPES[type]
    ),
    type: GTM_CONTENT_TYPES[type],
  }));

  return contentTypes;
};

export const getPurchaseData = (
  products: PurchaseStatisticsProduct[] = [],
  { id, tax, total_currency }: Order,
  iso: Currencies
): PurchaseData => {
  let revenue = 0;

  let productsArray = products.map<PurchaseDataProducts>(product => {
    let price = parseFloat(`${product.price}`);
    revenue += price;

    return {
      name: `Complete Anatomy (${product.bundle})`,
      price,
      quantity: 1,
    };
  });

  return {
    ecommerce: {
      currencyCode: total_currency,
      purchase: {
        actionField: {
          id,
          affiliation: 'store.3d4medical.com',
          revenue: revenue,
          tax: tax,
        },
        products: productsArray,
      },
    },
  };
};

const addStatistics = (
  products: PurchaseStatisticsProduct[],
  order: Order,
  iso: Currencies
) => {
  clearEcommerceObject();
  trackStatistics(GTM_EVENTS.checkout, getPurchaseData(products, order, iso));
};

const addDefaultEvent = (
  eventCategory = '',
  eventAction = '',
  eventLabel = ''
) => {
  const data = {
    eventCategory,
    eventAction,
    eventLabel,
  };

  trackStatistics(GTM_EVENTS.defaultEvent, data);
};

class GoogleTagManager {
  addPageView = () => {
    trackStatistics(GTM_EVENTS.pageView);
  };

  addBasketStatistics = (products, iso) => {
    //deprecated for GA
  };

  addPurchaseStatistics = (
    products: PurchaseStatisticsProduct[],
    order: Order,
    iso: Currencies
  ) => {
    addStatistics(products, order, iso);
  };

  sendConversionStatistic = (): void => {
    window['gtag']('event', 'conversion', {
      send_to: 'AW-11254275019/lgJvCLCa38kYEMu3uvYp',
      value: 1.0,
      currency: Currencies.USD,
      transaction_id: '',
    });
  };

  licensePage = {
    addPlayVideo(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();

      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      addDefaultEvent(
        GTM_EVENTS.licensePage[lowerCaseBundle].eventCategory,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventAction,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventLabel.playVideo
      );
    },

    addBuyNow(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();

      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      addDefaultEvent(
        GTM_EVENTS.licensePage[lowerCaseBundle].eventCategory,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventAction,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventLabel.buyNow
      );
    },

    addComparePlans(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();

      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      addDefaultEvent(
        GTM_EVENTS.licensePage[lowerCaseBundle].eventCategory,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventAction,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventLabel.comparePlans
      );
    },

    addLearnMore(featureSlug) {
      if (!featureSlug) {
        return;
      }

      trackStatistics(featureSlug);
    },

    arrowRight(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();

      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      addDefaultEvent(
        GTM_EVENTS.licensePage[lowerCaseBundle].eventCategory,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventAction,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventLabel.arrowRight
      );
    },

    arrowLeft(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();

      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      addDefaultEvent(
        GTM_EVENTS.licensePage[lowerCaseBundle].eventCategory,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventAction,
        GTM_EVENTS.licensePage[lowerCaseBundle].eventLabel.arrowLeft
      );
    },
  };

  pricingPage = {
    addIpadCH() {
      addDefaultEvent(
        GTM_EVENTS.pricingPage.eventCategory,
        GTM_EVENTS.pricingPage.eventAction,
        GTM_EVENTS.pricingPage.eventLabel.ipadCH
      );
    },

    addMacCH() {
      addDefaultEvent(
        GTM_EVENTS.pricingPage.eventCategory,
        GTM_EVENTS.pricingPage.eventAction,
        GTM_EVENTS.pricingPage.eventLabel.macCH
      );
    },

    addWindowsCH() {
      addDefaultEvent(
        GTM_EVENTS.pricingPage.eventCategory,
        GTM_EVENTS.pricingPage.eventAction,
        GTM_EVENTS.pricingPage.eventLabel.windowsCH
      );
    },

    addTiffStartToday() {
      addDefaultEvent(
        GTM_EVENTS.pricingPage.eventCategory,
        GTM_EVENTS.pricingPage.eventAction,
        GTM_EVENTS.pricingPage.eventLabel.tiffStartToday
      );
    },

    addTiffTFT() {
      addDefaultEvent(
        GTM_EVENTS.pricingPage.eventCategory,
        GTM_EVENTS.pricingPage.eventAction,
        GTM_EVENTS.pricingPage.eventLabel.tiffTFT
      );
    },

    addBuyNowStudent() {
      // migrated to enhanced ecommerce
    },

    addRequestDemoInstitutional() {
      // migrated to enhanced ecommerce
    },

    addBuyNowProfessional() {
      // migrated to enhanced ecommerce
    },

    addUpgradeProfessional() {
      // migrated to enhanced ecommerce
    },

    addToCart({ name, price, currency }: AddToCart) {
      price = price.slice(1);

      let segmentation = {
        ecommerce: {
          currencyCode: currency,
          add: {
            products: [
              {
                name: `Complete Anatomy (${name})`,
                price: parseFloat(price),
                quantity: 1,
              },
            ],
          },
        },
      };

      clearEcommerceObject();
      trackStatistics(GTM_EVENTS.addToCart, segmentation);
    },
  };

  subHeader = {
    addLogin() {
      addDefaultEvent(
        GTM_EVENTS.subHeader.eventCategory,
        GTM_EVENTS.subHeader.eventAction,
        GTM_EVENTS.subHeader.eventLabel.login
      );
    },

    addLogout() {
      addDefaultEvent(
        GTM_EVENTS.subHeader.eventCategory,
        GTM_EVENTS.subHeader.eventAction,
        GTM_EVENTS.subHeader.eventLabel.logout
      );
    },
  };

  confirmationPage = {
    addSetupGuid() {
      addDefaultEvent(
        GTM_EVENTS.confirmationPage.eventCategory,
        GTM_EVENTS.confirmationPage.eventAction,
        GTM_EVENTS.confirmationPage.eventLabel.setupGuid
      );
    },

    addTake2Minute() {
      addDefaultEvent(
        GTM_EVENTS.confirmationPage.eventCategory,
        GTM_EVENTS.confirmationPage.eventAction,
        GTM_EVENTS.confirmationPage.eventLabel.take2minute
      );
    },
  };

  checkout = {
    ecommerce: {
      currencyCode: '',
      checkout: {
        actionField: {},
        products: [],
      },
    },

    //Credit card payment method.
    addCreditCardPaymentMethod() {
      this.ecommerce.checkout.actionField.option = 'credit_card';

      let option = {
        checkout_option: {
          actionField: {
            step: 1,
            option: 'credit_card',
          },
        },
      };

      clearEcommerceObject();
      trackStatistics(GTM_EVENTS.checkoutOption, { ecommerce: option });
    },

    //Paypal payment method.
    addPayPalPaymentMethod() {
      this.ecommerce.checkout.actionField.option = 'paypal';

      let option = {
        checkout_option: {
          actionField: {
            step: 1,
            option: 'paypal',
          },
        },
      };

      clearEcommerceObject();
      trackStatistics(GTM_EVENTS.checkoutOption, { ecommerce: option });
    },

    addAliPayPaymentMethod() {
      //only for China
    },

    // Ecommerce step 1 - Select payment method
    addPaymentMethod({ products, currency, price }: PaymentMethod) {
      (this.ecommerce.currencyCode = currency),
        (this.ecommerce.checkout.actionField = {
          step: 1,
          affiliation: 'store.3d4medical.com',
        });
      this.ecommerce.checkout.products = products.map(product => {
        return {
          name: `Complete Anatomy (${product})`,
          price: price,
          quantity: 1,
        };
      });

      clearEcommerceObject();
      trackStatistics(GTM_EVENTS.checkout, { ecommerce: this.ecommerce });
    },

    //Ecommerce step 2 - Set billing info.
    addPaymentDetails() {
      if (
        !this.ecommerce.checkout.actionField.step ||
        this.ecommerce.checkout.products.length === 0
      ) {
        return;
      }

      this.ecommerce.checkout.actionField.step = 2;

      clearEcommerceObject();
      trackStatistics(GTM_EVENTS.checkout, { ecommerce: this.ecommerce });
    },

    //Ecommerce step 3 - Card detail & place order
    addPlaceOrder() {
      if (
        !this.ecommerce.checkout.actionField.step ||
        this.ecommerce.checkout.products.length === 0
      ) {
        return;
      }

      this.ecommerce.checkout.actionField.step = 3;

      clearEcommerceObject();
      trackStatistics(GTM_EVENTS.checkout, { ecommerce: this.ecommerce });
    },
  };

  accountPage = {
    turnOnAutoRenewSwitch() {
      addDefaultEvent(
        GTM_EVENTS.accountPage.eventCategory,
        GTM_EVENTS.accountPage.eventAction,
        GTM_EVENTS.accountPage.eventLabel.turnOnAutoRenewSwitch
      );
    },

    turnOffAutoRenewSwitch() {
      addDefaultEvent(
        GTM_EVENTS.accountPage.eventCategory,
        GTM_EVENTS.accountPage.eventAction,
        GTM_EVENTS.accountPage.eventLabel.turnOffAutoRenewSwitch
      );
    },

    turnOffAutoRenewWarning() {
      addDefaultEvent(
        GTM_EVENTS.accountPage.eventCategory,
        GTM_EVENTS.accountPage.eventAction,
        GTM_EVENTS.accountPage.eventLabel.turnOffAutoRenewWarning
      );
    },

    getOfferWarning() {
      addDefaultEvent(
        GTM_EVENTS.accountPage.eventCategory,
        GTM_EVENTS.accountPage.eventAction,
        GTM_EVENTS.accountPage.eventLabel.getOfferWarning
      );
    },
  };
}

export default new GoogleTagManager();
