import { PopupsActionType } from './enum';

export const showChangePurchasedSubscriptionPopup = () => ({
  type: PopupsActionType.ShowChangePurchasedSubscription,
  payload: {
    isOpen: true,
  },
});

export const hideChangePurchasedSubscriptionPopup = () => ({
  type: PopupsActionType.HideChangePurchasedSubscription,
  payload: {
    isOpen: false,
  },
});

export const showRegionSelectPopup = (force: { force: boolean }) => ({
  type: PopupsActionType.ShowRegionSelectPopup,
  payload: {
    isOpen: true,
    force,
  },
});

export const hideRegionSelectPopup = () => ({
  type: PopupsActionType.HideRegionSelectPopup,
  payload: {
    isOpen: false,
    force: false,
  },
});
