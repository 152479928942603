import { AppState } from '@redux/types';
import { CurrenciesState } from '@redux/reducers/currencies/types';

import { Currencies } from '@shared/enums/currency';
import { Currency } from '@shared/interfaces/currency';

export const _getCurrencies = (state: AppState): CurrenciesState => {
  return state.currencies;
};

export const _getCurrencyByISO = (
  state: AppState,
  iso: Currencies
): Currency => {
  const currencies = _getCurrencies(state);
  const currency = currencies.find(currency => currency.iso === iso);

  // TODO: Set a default
  return currency;
};
