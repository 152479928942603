import { BasketAction, BasketState } from '@redux/reducers/basket/types';
import { BasketActionType } from '@redux/reducers/basket/enum';

const initialState: BasketState = {
  items: [],
  loading: false,
};

function basketReducer(
  state = initialState,
  action: BasketAction
): BasketState {
  switch (action.type) {
    case BasketActionType.SetLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case BasketActionType.Set:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default basketReducer;
