import { ApiBasket } from '@redux/reducers/basket/types';

import { Bundle } from '@shared/enums/bundle';
import { SelectedSubscription } from '@shared/interfaces/selected-subscription';
import { Subscription } from '@shared/interfaces/subscription';
import { _getProductPrice } from '@shared/price/functions';
import _groupBy from 'lodash/groupBy';

export const _getProductType = (product: SelectedSubscription) => {
  if ('content_type' in product) {
    return product.content_type;
  } else {
    return 'subscription';
  }
};

export function _generateApiBasket(basket: Subscription[]): ApiBasket[] {
  const apiBasketByAppId = _groupBy(basket, 'appId');

  return Object.keys(apiBasketByAppId).map((item: string) => {
    const pricingProfileId = _getProductPrice(apiBasketByAppId[item][0]).id;

    return {
      profile: pricingProfileId,
      items: apiBasketByAppId[item].map(product => product.bundle),
    };
  });
}

export function _getTaxValue(taxRate: number) {
  return (100 * taxRate).toFixed(2);
}

export function _getBundlesBasket(
  basket: SelectedSubscription[] = []
): Bundle[] {
  return basket.reduce((prev, current) => [...prev, current.bundle], []);
}

export function _getAppIds(basket: Subscription[] = []) {
  return basket.reduce((acc, product) => {
    if (!acc.includes(product.appId)) {
      acc = [...acc, product.appId];
    }

    return acc;
  }, []);
}
