import isJsonString from './isJsonString';

export function removePayPalData() {
  window.localStorage.removeItem('paypalConfirmed');
  window.localStorage.removeItem('paypalError');
}

export function removeCancelRenewalData() {
  localStorage.removeItem('bundle');
  localStorage.removeItem('expireDate');
}

export function getBundle() {
  return localStorage.getItem('bundle');
}

export function getExpireDate() {
  return localStorage.getItem('expireDate');
}

export function getItemFromLocalStorage(name: string) {
  return isJsonString(window.localStorage.getItem(name))
    ? JSON.parse(window.localStorage.getItem(name))
    : null;
}
