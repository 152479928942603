import {
  AddToCart,
  GmtEvents,
  PaymentMethod,
  PurchaseDataProducts,
  PurchasedData,
  Segmentation,
} from '@utils/interfaces/google-analytics-4';
import isServer from '@utils/isServer';

import { Bundle } from '@shared/enums/bundle';
import { Currencies } from '@shared/enums/currency';
import { Order } from '@shared/interfaces/order';
import { PurchaseStatisticsProduct } from '@shared/interfaces/analytics';
import { readCookie } from './cookiesManager';

const GTM_EVENTS: GmtEvents = {
  loginMethod: 'accounts.3d4medical',
  affiliation: 'store.3d4medical.com',
  paymentMethod: {
    paypal: 'PayPal',
    creditCard: 'Credit Card',
  },
  checkout: {
    purchase: 'purchase',
    beginCheckout: 'begin_checkout',
    addPaymentInfo: 'add_payment_info',
    addBillingInfo: 'add_billing_info',
    addCardDetails: 'add_card_details',
  },
  licensePage: {
    [Bundle.Student]: {
      buyNow: 'BuyNow-Student',
      comparePlans: 'ComparePlans-Student',
    },
    [Bundle.Professional]: {
      buyNow: 'BuyNow-Professional',
      comparePlans: 'ComparePlans-Professional',
    },
    itemView: 'view_item',
  },
  pricingPage: {
    addToCart: 'add_to_cart',
    tiffStartToday: 'Tiff-Start-today',
    tiffTFT: 'Tiff-Take-the-Free-Trial',
    requestDemoInstitutional: 'RequestDemo-Institutional',
  },
  confirmationPage: {
    setupGuid: 'SetupGuid',
    take2minute: 'Take2minute',
  },
  accountPage: {
    login: 'login',
    logout: 'logout',
    turnOnAutoRenewSwitch: 'Turn on auto-renew switch',
    turnOffAutoRenewSwitch: 'Turn off auto-renew switch',
    turnOffAutoRenewWarning:
      'Select "Turn off auto-renew" in the alert warning',
    getOfferWarning: 'Select "Get offer" in the alert warning',
  },
};

const getBundleName = (name: string) => {
  let tempName = name.toLowerCase();

  switch (tempName) {
    case 'student':
    case Bundle.Student:
      tempName = 'Student';
      break;
    case 'professional':
    case Bundle.Professional:
      tempName = 'Professional';
      break;
    case 'institutional':
      tempName = 'Institutional demo request';
      break;
    case 'upgrade':
      tempName = 'Student - Upgrade to professional';
      break;
    default:
      return;
  }

  return `Complete Anatomy (${tempName})`;
};

const trackStatistics = (event: string, data = {}) => {
  if (
    !isServer() &&
    window['dataLayer'] &&
    JSON.parse(readCookie('showAnalyticsCookies'))
  ) {
    if (window['dataLayer']) {
    }
    // TODO: needs to confirm implementation
    // @ts-ignore
    function gtag() {
      window['dataLayer'].push(arguments);
    }
    // @ts-ignore
    gtag('event', event, data);
  }
};

export const getPurchaseData = (
  products: PurchaseStatisticsProduct[] = [],
  { id, tax, total_currency }: Order,
  iso: Currencies
): PurchasedData => {
  let revenue = 0;

  let productsArray = products.map<PurchaseDataProducts>(product => {
    let price = parseFloat(`${product.price}`);
    revenue += price;

    return {
      item_name: getBundleName(product.bundle),
      affiliation: GTM_EVENTS.affiliation,
      currency: total_currency,
      price,
      quantity: 1,
    };
  });

  return {
    transaction_id: id,
    value: revenue,
    tax: parseFloat(tax),
    currency: total_currency,
    items: productsArray,
  };
};

class GoogleAnalytics4 {
  addItemView = (itemName: string) => {
    let bundleName = getBundleName(itemName);
    if (bundleName === undefined) {
      return;
    }

    const segmentation = {
      items: [
        {
          item_name: bundleName,
          affiliation: GTM_EVENTS.affiliation,
          quantity: 1,
        },
      ],
    };
    trackStatistics(GTM_EVENTS.licensePage.itemView, segmentation);
  };

  addPurchaseStatistics = (
    products: PurchaseStatisticsProduct[] = [],
    order: Order,
    iso: Currencies
  ) => {
    trackStatistics(
      GTM_EVENTS.checkout.purchase,
      getPurchaseData(products, order, iso)
    );
  };

  licensePage = {
    addBuyNow(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();
      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      trackStatistics(GTM_EVENTS.licensePage[lowerCaseBundle].buyNow);
    },

    addComparePlans(licenseBundle = '') {
      const lowerCaseBundle = licenseBundle.toLowerCase();
      if (!GTM_EVENTS.licensePage[lowerCaseBundle]) {
        return;
      }

      trackStatistics(GTM_EVENTS.licensePage[lowerCaseBundle].comparePlans);
    },
  };

  pricingPage = {
    addRequestDemoInstitutional() {
      trackStatistics(GTM_EVENTS.pricingPage.requestDemoInstitutional);
    },

    addTiffStartToday() {
      trackStatistics(GTM_EVENTS.pricingPage.tiffStartToday);
    },

    addTiffTFT() {
      trackStatistics(GTM_EVENTS.pricingPage.tiffTFT);
    },

    addToCart({ name, price, currency }: AddToCart) {
      price = price.slice(1);

      let bundleName = getBundleName(name);
      if (bundleName === undefined) {
        return;
      }

      const segmentation: Segmentation = {
        currency,
        value: parseFloat(price),
        items: [
          {
            item_name: bundleName,
            price,
            currency,
            quantity: 1,
          },
        ],
      };

      trackStatistics(GTM_EVENTS.pricingPage.addToCart, segmentation);
    },
  };

  subHeader = {
    addLogin() {
      trackStatistics(GTM_EVENTS.accountPage.login, {
        method: GTM_EVENTS.loginMethod,
      });
    },

    addLogout() {
      trackStatistics(GTM_EVENTS.accountPage.logout);
    },
  };

  confirmationPage = {
    addSetupGuid() {
      trackStatistics(GTM_EVENTS.confirmationPage.setupGuid);
    },

    addTake2Minute() {
      trackStatistics(GTM_EVENTS.confirmationPage.take2minute);
    },
  };

  checkout = {
    ecommerce: {
      currency: undefined,
      value: undefined,
      items: [],
    },

    addCreditCardPaymentMethod() {
      this.ecommerce.payment_type = GTM_EVENTS.paymentMethod.creditCard;
      trackStatistics(GTM_EVENTS.checkout.addPaymentInfo, this.ecommerce);
    },

    addPayPalPaymentMethod() {
      this.ecommerce.payment_type = GTM_EVENTS.paymentMethod.paypal;
      trackStatistics(GTM_EVENTS.checkout.addPaymentInfo, this.ecommerce);
    },

    addAliPayPaymentMethod() {
      //only for China
    },

    // Checkout step 1 - Select payment method
    addPaymentMethod({ products, currency, price }: PaymentMethod) {
      this.ecommerce.currency = currency;
      this.ecommerce.value = price;
      this.ecommerce.items = products.map(product => {
        return {
          item_name: getBundleName(product),
          affiliation: GTM_EVENTS.affiliation,
          currency,
          price,
          quantity: 1,
        };
      });

      trackStatistics(GTM_EVENTS.checkout.beginCheckout, this.ecommerce);
    },

    //Checkout step 2 - Set billing info.
    addPaymentDetails() {
      trackStatistics(GTM_EVENTS.checkout.addBillingInfo);
    },

    //Checkout step 3 - Card detail & place order
    addPlaceOrder() {
      trackStatistics(GTM_EVENTS.checkout.addCardDetails);
    },
  };

  accountPage = {
    turnOnAutoRenewSwitch() {
      trackStatistics(GTM_EVENTS.accountPage.turnOnAutoRenewSwitch);
    },

    turnOffAutoRenewSwitch() {
      trackStatistics(GTM_EVENTS.accountPage.turnOffAutoRenewSwitch);
    },

    turnOffAutoRenewWarning() {
      trackStatistics(GTM_EVENTS.accountPage.turnOffAutoRenewWarning);
    },

    getOfferWarning() {
      trackStatistics(GTM_EVENTS.accountPage.getOfferWarning);
    },
  };
}

export default new GoogleAnalytics4();
