import { PriceInformation } from '@redux/reducers/applications/types';

import { APP_DISCOUNT_TYPE } from '../application/constants';
import { Cost } from '@shared/interfaces/price';
import { Currencies } from '@shared/enums/currency';
import { Currency } from '@shared/interfaces/currency';
import { Subscription } from '@shared/interfaces/subscription';

function _getPrice(costs: Cost[], tier: number, currencyIso: Currencies) {
  return costs.find(
    price => price.tier === tier && price.amount_currency === currencyIso
  );
}

function _getProductDiscountType(product: Subscription) {
  const salePrices = product.sale?.price?.costs;

  if (salePrices?.length) {
    return APP_DISCOUNT_TYPE.SALE;
  }

  return APP_DISCOUNT_TYPE.REG;
}

export function _getProductPrice(product: Subscription) {
  const discountType = _getProductDiscountType(product);
  switch (true) {
    case discountType === APP_DISCOUNT_TYPE.SALE:
      return product.sale.price;
    case discountType === APP_DISCOUNT_TYPE.REG:
    default:
      return product.price;
  }
}

export function _getPriceInformation(
  product: Subscription,
  currency: Partial<Currency>
): PriceInformation {
  let price: number;
  let regPrice = _getPrice(product.price.costs, 1, currency.iso)?.amount;
  let symbol = currency.symbol;
  let type = 'reg';
  const prices = _getProductPrice(product);

  // Subscription
  price = _getPrice(prices.costs, 1, currency.iso)?.amount;

  if (price !== regPrice) {
    type = 'subscriptionSale';
  }

  return {
    price,
    regPrice,
    symbol,
    type,
    iso: currency.iso,
  };
}

export function _getSalePercent(regPrice: number, salePrice: number) {
  return ((regPrice - salePrice) / regPrice) * 100;
}

export function calculatePriceByPercent(price: number, percent: number) {
  return price - (price / 100) * percent;
}

export function getPriceISOString(
  symbol: string | number,
  price: number,
  iso: Currencies
) {
  return `${symbol}${price.toFixed(2)} ${iso}`;
}

export function getPriceYearString(symbol: string, price: number) {
  return `${symbol}${price.toFixed(2)}/year`;
}
