import { AccessTokenWarningActionType } from '@redux/reducers/accessTokenWarning/enum';

export const showAccessTokenWarning = () => ({
  type: AccessTokenWarningActionType.Show,
  payload: {
    show: true,
  },
});

export const hideAccessTokenWarning = () => ({
  type: AccessTokenWarningActionType.Hide,
  payload: {
    show: false,
  },
});
