import { CurrencyAction, CurrencyState } from '@redux/reducers/currency/types';
import { CurrencyActionType } from '@redux/reducers/currency/enum';

import { Currency } from '@shared/interfaces/currency';

const initialState: CurrencyState = {
  currentCurrency: {} as Currency,
};

function currencyReducer(
  state = initialState,
  action: CurrencyAction
): CurrencyState {
  switch (action.type) {
    case CurrencyActionType.Set:
      return { ...state, currentCurrency: action.payload };
    default:
      return state;
  }
}

export default currencyReducer;
