import { ROUTE } from '@utils/index';

import Router from 'next/router';

const redirect = (res?, path = ROUTE.PRICING, status = 302) => {
  if (res) {
    res.redirect(status, path);
  } else {
    Router.replace(path);
  }
};

export default redirect;
