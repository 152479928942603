import {
  AccessTokenWarningAction,
  AccessTokenWarningState,
} from '@redux/reducers/accessTokenWarning/types';
import { AccessTokenWarningActionType } from '@redux/reducers/accessTokenWarning/enum';

const initialState: AccessTokenWarningState = { show: false };

function accessTokenReducer(
  state = initialState,
  action: AccessTokenWarningAction
): AccessTokenWarningState {
  switch (action.type) {
    case AccessTokenWarningActionType.Show:
      return action.payload;

    case AccessTokenWarningActionType.Hide:
      return action.payload;

    default:
      return state;
  }
}

export default accessTokenReducer;
