const { env } = require('next-runtime-env/build/env');

const NextI18Next = require('next-i18next').default;
const path = require('path');
const otherLanguages = require('./translation/languages.json');

const BASE_URI = env('NEXT_PUBLIC_BASE_URI') || '3d4medical.com';

const nextI18NextInstance = new NextI18Next({
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'header'],
    lookupQuerystring: 'lang',
    lookupCookie: 'wp-wpml_current_language',
    cookieDomain: `.${
      BASE_URI.includes('3d4medical.com')
        ? '3d4medical.com'
        : 'completeanatomy.cn'
    }`,
  },
  otherLanguages: otherLanguages,
  localePath:
    typeof window === 'undefined'
      ? path.resolve('./public/locales')
      : '/locales',
});

export const {
  ORIGIN,
  Trans,
  Link,
  Router,
  i18n,
  config,
  withTranslation,
  appWithTranslation,
  useTranslation,
} = nextI18NextInstance;

export default nextI18NextInstance;
