export const ROUTE = {
  PRICING: '/',
  ACCOUNT: {
    INDEX: '/account',
    PREFERENCES: {
      INDEX: '/account/preferences',
      DELETE_ACCOUNT: '/account/preferences/delete',
    },
  },
  B2C_SUNSET: '/b2cedu-sunset',
  COUNTRIES: '/countries',
  APPLICATION: {
    LICENSE: (appSlug, subscriptionSlug) =>
      `/${appSlug}/license/${subscriptionSlug}`,
    LICENSE_SCHEMA: '/[app-slug]/license/[subscription-slug]',
    LICENSES: appSlug => `/${appSlug}/licenses`,
    LICENSES_SCHEMA: '/[app-slug]/licenses',
    DISCOUNT_SCHEMA: '/[app-slug]/discount',
  },
  CANCELLATION: {
    FEEDBACK: '/cancellation/feedback',
    DISCOUNT: '/cancellation/discount',
    DETAILS: '/cancellation/details',
    CONFIRMATION: '/cancellation/confirmation',
    FEEDBACK_CONFIRMATION: '/cancellation/feedback-confirmation',
    DISCOUNT_CONFIRMATION: '/cancellation/discount-confirmation',
  },
  CHECKOUT: {
    SUBSCRIPTION: subscriptionSlug => `/checkout/${subscriptionSlug}`,
    SUBSCRIPTION_SCHEMA: '/checkout/[subscription-slug]',
    SELECT: '/checkout/select',
    BILLING: '/checkout/billing',
    ORDER: '/checkout/order',
    CONFIRMATION: '/checkout/confirmation',
    PAYPAL: '/checkout/paypal',
    ALIPAY: '/checkout/alipay',
  },
};
