import { PopupsAction, PopupsState } from '@redux/reducers/popups/types';
import { PopupsActionType } from '@redux/reducers/popups/enum';

const initialState: PopupsState = {
  changePurchasedSubscription: {
    isOpen: false,
  },
  regionSelect: {
    isOpen: false,
    force: false,
  },
};

function popupsReducer(
  state = initialState,
  action: PopupsAction
): PopupsState {
  switch (action.type) {
    case PopupsActionType.ShowChangePurchasedSubscription:
      return {
        ...state,
        changePurchasedSubscription: action.payload,
      };
    case PopupsActionType.HideChangePurchasedSubscription:
      return {
        ...state,
        changePurchasedSubscription: action.payload,
      };
    case PopupsActionType.ShowRegionSelectPopup:
    case PopupsActionType.HideRegionSelectPopup:
      return {
        ...state,
        regionSelect: action.payload,
      };
    default:
      return state;
  }
}

export default popupsReducer;
