import { ProfileWithFullName } from '@redux/reducers/profile/types';

import GoogleAnalytics4 from '@utils/googleAnalytics4';
import GoogleTagManager from '@utils/googleTagManager';
import { isCN } from '@utils/origin';

import AdobeAnalytics from './adobeAnalytics';

class AnalyticsManager {
  setUserProfile(profile: ProfileWithFullName) {
    if (isCN) {
      AdobeAnalytics.setUserProfile(profile);
    }
  }

  get shared() {
    if (isCN) {
      return AdobeAnalytics;
    } else {
      return GoogleAnalytics4;
    }
  }

  get googleLegacy() {
    return GoogleTagManager;
  }

  get google() {
    return GoogleAnalytics4;
  }

  get adobeAnalytics() {
    return AdobeAnalytics;
  }
}

export default new AnalyticsManager();
