import { APP_PRODUCT_FIELDS } from '@shared/application/constants';
import { Product } from '@shared/interfaces/product';
import { _getActiveProducts } from 'shared/product/functions';

export function _addAppIdToProducts(applications: Product[] = []): Product[] {
  return applications.map(app => {
    const appId = app.id;

    APP_PRODUCT_FIELDS.forEach(field => {
      const productsWithAppId: Product[] = app[field].map(item => ({
        ...item,
        appId,
      }));
      app[field] = _getActiveProducts(productsWithAppId);
    });

    return app;
  });
}
