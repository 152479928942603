import React, { PureComponent } from 'react';

import NextHead from 'next/head';
import { NextRouter } from 'next/dist/client/router';
import { withRouter } from 'next/router';

interface CustomNextRouter extends NextRouter {
  query: {
    promo: string;
    clickId: string;
  };
}

type PageTitleProps = {
  title: string;
  router: CustomNextRouter;
};

class PageTitle extends PureComponent<PageTitleProps> {
  render() {
    const {
      router: { query },
    } = this.props;
    const hasPromo = query.promo || query.clickId;

    return (
      <NextHead data-cy="pageTitle-test">
        {hasPromo && <meta name="robots" content="noindex, nofollow" />}
        <title>{this.props.title}</title>
      </NextHead>
    );
  }
}

export default withRouter(PageTitle);
