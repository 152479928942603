import { AppState } from '@redux/types';
import { ApplicationPrices } from '@redux/reducers/applications/types';
import { _getCurrency } from '@redux/reducers/profile/selectors';

import { APP_PRODUCT_FIELDS, APP_SLUG } from '@shared/application/constants';
import { Currency } from '@shared/interfaces/currency';
import { Product } from '@shared/interfaces/product';
import { Subscription } from '@shared/interfaces/subscription';
import { _getPriceInformation } from '@shared/price/functions';

export function _getCaApp(state: AppState, fields?): Product {
  const applications = _getApps(state);
  const caSlug = APP_SLUG.CA.toLowerCase();

  let application = applications.find(app => app.slug.toLowerCase() === caSlug);

  if (fields && fields.length) {
    application = fields.reduce((acc, field) => {
      return {
        ...acc,
        [field]: application[field],
      };
    }, {});
  }

  return application;
}

export function _isLoadingApps(state: AppState): boolean {
  return state.applications.loading;
}

export function _getApps(state: AppState): Product[] {
  return state.applications.items;
}

export function _getAppById(id: number, state: AppState): Product {
  const applications = _getApps(state);

  return applications.find(application => application.id === id);
}

export function _getAppBySlug(state: AppState, slug: string): Product {
  const applications = _getApps(state);

  return applications.find(application => application.slug === slug);
}

export function _getSubscriptionBySlug(
  state: AppState,
  appSlug: string,
  subscriptionSlug: string
): Subscription {
  const appication = _getAppBySlug(state, appSlug);

  return appication.subscriptions.find(
    subscription => subscription.slug === subscriptionSlug
  );
}

export function _getPrices(
  state: AppState,
  customCurrency?: Partial<Currency>
): ApplicationPrices[] {
  const applications = _getApps(state);
  const currency = _getCurrency(state);
  let prices: ApplicationPrices[] = [];

  applications.forEach(app => {
    APP_PRODUCT_FIELDS.forEach(field => {
      app[field].forEach((product: Subscription) => {
        prices = [
          ...prices,
          {
            bundle: product.bundle,
            selected: _getPriceInformation(
              product,
              customCurrency ? customCurrency : currency
            ),
          },
        ];
      });
    });
  });

  return prices;
}
