import { AppState } from '@redux/types';
import { CountriesState } from '@redux/reducers/countries/types';
import { CountryActionType } from '@redux/reducers/country/enum';

import { ORIGIN } from '@utils/origin';
import { clientCookies } from '@utils/clientCookies';
import { nextYear } from '@utils/date';

import Api from '@api/index';
import Cookies from 'universal-cookie';
import { Country } from '@shared/interfaces/country';
import { _getAuth, _isAuthorized } from '../auth/selectors';
import { _getCountries } from '../countries/selectors';
import { _getProfile } from '../profile/selectors';
import { _sendError } from '@shared/logger/functions';
import { updateProfile } from '../profile/action';

const loadingCountry = (isLoading: boolean) => {
  return {
    type: CountryActionType.LoadingCountry,
    payload: isLoading,
  };
};

const userLocation = (country: Country) => {
  return {
    type: CountryActionType.UserLocation,
    payload: country,
  };
};

export const setCountry =
  (country: Country) =>
  async (dispatch, getState: () => AppState): Promise<{ has403: boolean }> => {
    const state = getState();
    const auth = _getAuth(state);

    if (_isAuthorized(state)) {
      try {
        await Api.setCountry({ iso: country.iso }, auth);
      } catch (error) {
        _sendError('The API method setCountry() was failed.', error);

        if (error && error.response && error.response.status === 403) {
          return Promise.reject({ has403: true });
        }
      }
    }

    dispatch(saveCountry(country));
  };

const saveCountry = (country: Country) => dispatch => {
  clientCookies.set('region_3d4m', country.iso, {
    path: '/',
    domain: `.${ORIGIN}`,
    expires: nextYear(),
  });
  dispatch(
    updateProfile({
      country,
    })
  );
};

export const setInitialCountry =
  (cookies: Cookies, fromQuery: string) =>
  (dispatch, getState: () => AppState) => {
    const state = getState();
    const isAuthorized = _isAuthorized(state);
    const profile = _getProfile(state);
    const countries = _getCountries(state);

    if (isAuthorized && !fromQuery) {
      dispatch(saveCountry(profile.country));
    } else {
      const cookieRegionISO: string = cookies.get('region_3d4m');
      let country: Country;

      if (fromQuery) {
        country = countries.find(country => country.iso === fromQuery);
      } else if (cookieRegionISO) {
        country = countries.find(country => country.iso === cookieRegionISO);
      }

      if (country) {
        dispatch(saveCountry(country));
      } else if (cookieRegionISO) {
        cookies.remove('region_3d4m', { path: '/' });
      }
    }
  };

export const detectCountry =
  (fromQuery: string) => async (dispatch, getState: () => AppState) => {
    const state = getState();
    const countries = _getCountries(state);
    const isAuthorized = _isAuthorized(state);
    const cookieCountryISO: string = clientCookies.get('region_3d4m');

    try {
      dispatch(loadingCountry(true));

      let country: CountriesState | Country;

      if (fromQuery && cookieCountryISO !== fromQuery) {
        country = countries.find(({ iso }) => iso === fromQuery);
      } else if (cookieCountryISO) {
        country = countries.find(({ iso }) => iso === cookieCountryISO);
      } else {
        country = await Api.detectCountry();
      }

      dispatch(userLocation(country));

      if (!isAuthorized && (!cookieCountryISO || fromQuery)) {
        dispatch(saveCountry(country));
      }

      dispatch(loadingCountry(false));
    } catch (error) {
      _sendError(`The API method detectCountry() was failed.`, error);
    }
  };
