import { BillingAction } from '@redux/reducers/customer/billing/types';
import { BillingActionType } from '@redux/reducers/customer/billing/enum';

import { Billing } from '@shared/interfaces/billing';

function billingReducer(state = null, action: BillingAction): Billing {
  switch (action.type) {
    case BillingActionType.Set:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default billingReducer;
