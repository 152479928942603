import {
  QueryParamsAction,
  QueryState,
} from '@redux/reducers/queryParams/types';
import { QueryParamsActionType } from '@redux/reducers/queryParams/enum';

const initialState: QueryState = {};

function queryParamsReducer(
  state = initialState,
  action: QueryParamsAction
): QueryState {
  let query;

  switch (action.type) {
    case QueryParamsActionType.Set:
      if (action.payload.affiliate) {
        action.payload.affiliate = action.payload.affiliate === 'true';
      }
      if (action.payload.courseId) {
        action.payload.courseId = +action.payload.courseId;
      }
      query = {
        ...state,
        ...action.payload,
      };
      break;
    default:
      query = state;
  }

  return query;
}

export default queryParamsReducer;
