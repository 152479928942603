const width = Math.min(window.screen.width, 600);
const height = Math.min(window.screen.height, 800);

export interface DefaultParams {
  width: number;
  height: number;
  top: number;
  left: number;
  resizable: string;
  scrollbars: string;
  status: string;
}

export type WindowParams = Partial<DefaultParams>;

const defaultParams: DefaultParams = {
  width: width,
  height: height,
  top: (window.screen.height - height) / 2,
  left: (window.screen.width - width) / 2,
  resizable: 'yes',
  scrollbars: 'yes',
  status: 'yes',
};

export const openNewWindow = (
  url,
  params: WindowParams = {},
  newTab?: boolean
): Promise<{ closed: boolean }> => {
  const objParams = { ...defaultParams, ...params };
  const stringParams = Object.keys(objParams)
    .map(paramName => paramName + '=' + objParams[paramName])
    .join(',');

  return new Promise(resolve => {
    const popup = newTab ? 
      window.open(url, '_blank')  //open in new tab.
      : window.open(url, null, stringParams); //open in new window.

    const timer = setInterval(() => {
      if (popup && popup.closed) {
        clearInterval(timer);
        resolve({ closed: true });
      }
    }, 1000);
  });
};
