import { QueryState } from '@redux/reducers/queryParams/types';

import { WindowParams, openNewWindow } from '@utils/openNewWindow';

import queryString from 'query-string';

class Accounts {
  _generateOauthAuthorize = (
    clientId: string,
    oauthRedirectUri: string,
    query?: QueryState
  ): string => {
    const params = {
      response_type: 'token',
      redirect_uri: oauthRedirectUri,
      client_id: clientId,
    };
    const source = '&source=STORE';
    const promo = query?.promo ? `&3d4m_usr_origin=${query.promo}` : '';

    return (
      '/oauth2/authorize/?' +
      encodeURIComponent(queryString.stringify(params)) +
      source +
      promo
    );
  };

  createAccount = (
    oauthUri: string,
    oauthRedirectUri: string,
    clientId: string,
    query: QueryState
  ): Promise<{ closed: boolean }> => {
    const redirectUri = this._generateOauthAuthorize(
      clientId,
      oauthRedirectUri,
      query
    );

    return openNewWindow(`${oauthUri}/create-account?next=${redirectUri}`);
  };

  confirmCredentials = (
    oauthUri: string,
    oauthRedirectUri: string,
    clientId: string,
    query?: QueryState
  ) => {
    const redirectUri = this._generateOauthAuthorize(
      clientId,
      oauthRedirectUri,
      query
    );

    return openNewWindow(`${oauthUri}/confirm-credentials?next=${redirectUri}`);
  };

  changeImage = (
    oauthUri: string,
    redirectUri: string,
    params: WindowParams
  ) => {
    return openNewWindow(
      `${oauthUri}/account/edit/image?next=${encodeURIComponent(redirectUri)}`,
      params
    );
  };

  changePassword = (
    oauthUri: string,
    oauthRedirectUri: string,
    clientId: string
  ) => {
    const redirectUri = this._generateOauthAuthorize(
      clientId,
      oauthRedirectUri
    );

    return openNewWindow(
      `${oauthUri}/account/edit/password?next=${redirectUri}`
    );
  };

  changeInfo = (oauthUri: string, redirectUri: string) => {
    return openNewWindow(
      `${oauthUri}/account/edit/info?next=${encodeURIComponent(redirectUri)}`, null, true
    );
  };

  changeEmailPreferences = (
    oauthUri: string,
    redirectUri: string,
    params?: WindowParams
  ) => {
    return openNewWindow(
      `${oauthUri}/account/preferences/email?next=${encodeURIComponent(
        redirectUri
      )}`,
      params
    );
  };
}

export default new Accounts();
