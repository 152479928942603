export const APP_DISCOUNT_TYPE = {
  REG: 1,
  SALE: 2,
};

export const APP_SLUG = {
  CA: 'complete-anatomy',
};

export const APP_PRODUCT_FIELDS = ['subscriptions'];

export const LANGUAGE = {
  English: 'en',
  Spanish: 'es',
  Chinese: 'zh',
  French: 'fr',
  German: 'de',
};
