import { ENVDOMAIN, ORIGIN } from '@utils/origin';

export function readCookie(name: string) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

export function listenCookieChange(
  cookieName: string,
  callback: (payload: string | boolean) => void
) {
  let cookieRegistry = [];
  setInterval(function () {
    const valueCookie = readCookie(cookieName);
    if (valueCookie !== cookieRegistry[cookieName]) {
      cookieRegistry[cookieName] = valueCookie;

      return callback(cookieRegistry[cookieName]);
    }
  }, 1000);
}

export function deleteCookies() {
  const defaultRequiredCookies = [
    'showCookiesBanner',
    'showAnalyticsCookies',
    'sessionid',
    'wp-wpml_current_language',
    'region_3d4m',
    'currency_3d4m',
  ];
  let allCookies = document.cookie.split('; ');
  let analyticsCookies = allCookies
    .filter(
      cookie => !defaultRequiredCookies.some(c => cookie.indexOf(c) !== -1)
    )
    .concat(['fr']);
  for (let i in analyticsCookies) {
    const cookieName = /^[^=]+/.exec(analyticsCookies[i])[0];
    document.cookie = `${cookieName}=; path=/; domain=.${ORIGIN}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    document.cookie = `${cookieName}=; path=/; domain=store.${ENVDOMAIN}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
