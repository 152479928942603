import {
  ApplicationsAction,
  ApplicationsState,
} from '@redux/reducers/applications/types';
import { ApplicationsActionType } from '@redux/reducers/applications/enum';

const initialState: ApplicationsState = {
  loading: false,
  items: [],
};

function applicationsReducer(
  state = initialState,
  action: ApplicationsAction
): ApplicationsState {
  switch (action.type) {
    case ApplicationsActionType.Get:
      return {
        ...state,
        loading: true,
      };

    case ApplicationsActionType.Set:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };

    default:
      return state;
  }
}

export default applicationsReducer;
