import {
  CurrenciesAction,
  CurrenciesState,
} from '@redux/reducers/currencies/types';
import { CurrenciesActionType } from '@redux/reducers/currencies/enum';

import { Currencies } from '@shared/enums/currency';
import { Currency } from '@shared/interfaces/currency';

const USD: Currency = {
  id: 1,
  iso: Currencies.USD,
  symbol: '$',
  rate: 1,
  default: true,
  paypal: true,
  stripe: true,
  alipay: false,
  zero_decimal: false,
};

const CNY: Currency = {
  id: 4,
  iso: Currencies.CNY,
  symbol: '¥',
  rate: 6.7089,
  default: false,
  paypal: false,
  stripe: true,
  alipay: true,
  zero_decimal: false,
};

const initialState: CurrenciesState = [USD, CNY];

function currenciesReducer(
  state = initialState,
  action: CurrenciesAction
): CurrenciesState {
  switch (action.type) {
    case CurrenciesActionType.LoadCurrencies:
      return action.payload;
    default:
      return state;
  }
}

export default currenciesReducer;
