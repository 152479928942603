import { SalesAction, SalesState } from '@redux/reducers/sales/types';
import { SalesActionType } from '@redux/reducers/sales/enum';

function salesReducer(state: SalesState = {}, action: SalesAction): SalesState {
  switch (action.type) {
    case SalesActionType.Set:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default salesReducer;
