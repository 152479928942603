import { Action, Dispatch } from 'redux';
import { AppState } from '@redux/types';
import { SalesActionType } from '@redux/reducers/sales/enum';
import { SalesState } from '@redux/reducers/sales/types';
import { _getSales } from '@redux/reducers/sales/selectors';

import { APP_PRODUCT_FIELDS } from '@shared/application/constants';
import { Product } from '@shared/interfaces/product';
import { Subscription } from '@shared/interfaces/subscription';
import _isEqual from 'lodash/isEqual';

const setSaleInfo = (info: SalesState) => ({
  type: SalesActionType.Set,
  payload: info,
});

export const setSale =
  (application: Product) =>
  (dispatch: Dispatch<Action<SalesActionType>>, getState: () => AppState) => {
    const state = getState();
    const sale = _getSales(state, application.id);
    let newSale: SalesState = {};

    APP_PRODUCT_FIELDS.some(field => {
      if (application[field].length) {
        const firstProduct: Subscription = application[field][0];
        newSale = {
          [application.id]: firstProduct.sale,
        };

        return true;
      }

      return false;
    });

    if (!_isEqual(sale, newSale)) {
      dispatch(setSaleInfo(newSale));
    }
  };
