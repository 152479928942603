import { AxiosError } from 'axios';

export const _sendError = (message: string, error?: AxiosError) => {
  let responseStatus = '';
  let responseStatusText = '';
  let responseData = '';
  let responseConfigData = '';

  if (error?.response) {
    if (error.response.status) {
      responseStatus = `Error status: ${error.response.status}.`;
    }

    if (error.response.statusText) {
      responseStatusText = `Error statusText: ${error.response.statusText}.`;
    }

    if (JSON && error.response.data) {
      responseData = `Error response data: ${JSON.stringify(
        error.response.data
      )}.`;
    }

    if (error.response.config && error.response.config.data) {
      responseConfigData = `Request data: ${error.response.config.data}`;
    }
  }

  const template = `
  ${message}
  ${responseConfigData}
  ${error ?? {}}
  ${responseStatus}
  ${responseStatusText}
  ${responseData}
  `;

  console.error(template);
};
