import { AppState } from '@redux/types';

import { _getProfile } from 'redux/reducers/profile/selectors';

import { Bundle } from '@shared/enums/bundle';
import { Subscription } from '@shared/interfaces/subscription';
import { _getProductByBundle } from '@shared/product/functions';
import { _getTaxValue } from '@shared/basket/functions';

export function _getObjectsBasket(
  state: AppState,
  basketBundles: Bundle[] = []
): Subscription[] {
  const basket = _getBasket(state);
  const bundles = basketBundles.length > 0 ? basketBundles : basket;

  return bundles
    .reduce(
      (prev, currentBundle) => [
        ...prev,
        _getProductByBundle(currentBundle, state),
      ],
      []
    )
    .filter(Boolean);
}

export function _isAddingToBasket(state: AppState): boolean {
  return state.basket.loading;
}

export function _getBasket(state: AppState) {
  return state.basket.items;
}

// TODO: Update "prices" type when the component will be updated to TS
export function _getCheckoutSummary(state: AppState, prices) {
  const profile = _getProfile(state);
  const basket = _getObjectsBasket(state);

  let total = 0;
  let tax = 0;
  const taxRate = profile.country.tax_rate;

  basket.forEach(product => {
    const { selected } = prices.find(price => price.bundle === product.bundle);
    const price = selected.price ?? selected.regPrice;
    tax += (price * taxRate) / (1 + taxRate);
    total += price;
  });

  const taxValue = _getTaxValue(taxRate);

  return {
    total,
    tax,
    taxValue,
  };
}
