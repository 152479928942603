import { ORIGIN, isCN } from '@utils/origin';

export enum Status {
  Nothing = 'nothing',
  Loading = 'loading',
}

export enum PaymentType {
  Card = 'card',
  Paypal = 'paypal',
  Alipay = 'alipay',
}

export const CARD_TYPE = {
  Visa: {
    name: 'Visa',
    src: 'https://cdn.3d4medical.com/store/images/visa.png',
    srcSet: [
      'https://cdn.3d4medical.com/store/images/visa.png',
      'https://cdn.3d4medical.com/store/images/visa@2x.png',
      'https://cdn.3d4medical.com/store/images/visa%403x.png',
    ],
  },
  MasterCard: {
    name: 'MasterCard',
    src: 'https://cdn.3d4medical.com/store/images/masterCard.png',
    srcSet: [
      'https://cdn.3d4medical.com/store/images/masterCard.png',
      'https://cdn.3d4medical.com/store/images/masterCard@2x.png',
      'https://cdn.3d4medical.com/store/images/masterCard@3x.png',
    ],
  },
  'American Express': {
    name: 'American Express',
    src: 'https://cdn.3d4medical.com/store/images/americanExpress.png',
    srcSet: [
      'https://cdn.3d4medical.com/store/images/americanExpress.png',
      'https://cdn.3d4medical.com/store/images/americanExpress@2x.png',
      'https://cdn.3d4medical.com/store/images/americanExpress@3x.png',
    ],
  },
  'Diners Club': {
    name: 'Diners Club',
    src: 'https://cdn.3d4medical.com/store/images/club.png',
    srcSet: [
      'https://cdn.3d4medical.com/store/images/club.png',
      'https://cdn.3d4medical.com/store/images/club@2x.png',
      'https://cdn.3d4medical.com/store/images/club@3x.png',
    ],
  },
  JCB: {
    name: 'JCB',
    src: 'https://cdn.3d4medical.com/store/images/jcb.png',
    srcSet: [
      'https://cdn.3d4medical.com/store/images/jcb.png',
      'https://cdn.3d4medical.com/store/images/jcb@2x.png',
      'https://cdn.3d4medical.com/store/images/jcb@3x.png',
    ],
  },
};

export const DEFAULT_ICON = '/manifest/icons/192x192.png';
export const DEFAULT_AVATAR =
  'https://cdn.3d4medical.com/store/images/default-avatar2.svg';

export const STORE_SOURCE = {
  OUR: '3d4medical',
  OFFICIAL: 'official',
  REDEEM: 'redeem',
  MANUAL: 'manual',
};

export enum HeaderType {
  Ca = 'ca',
}

export const SETUP_LINK =
  'https://3d4medical.com/support/complete-anatomy/setup-guide';
export const TRIAL_SETUP_LINK = `${SETUP_LINK}?trial`;

export const LONG_CURRENCIES = ['JPY', 'RUB'];

export const CA_ICON = `https://cdn.${ORIGIN}/website/branding/icons/completeAnatomy2022_icon.png`;

const institutionalPngLink = isCN
  ? 'https://cdn.completeanatomy.cn/website/branding/icons/institution.svg'
  : 'https://cdn.3d4medical.com/common/store/images/institution.svg';

export const SUBSCRIPTION_ICONS = {
  individual:
    'https://cdn.3d4medical.com/accounts/static/store/flows/individual.svg',
  institutional: institutionalPngLink,
};

export const DATE_FORMAT = 'MMM D, YYYY';
