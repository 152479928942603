export enum ProfileActionType {
  Set = 'profile/SET_PROFILE',
  Update = 'profile/UPDATE_PROFILE',
  Clear = 'profile/CLEAR_PROFILE',
}

export enum AutomaticRenewalState {
  Enabled = 0,
  Disabled = 1,
  BillingRetry = 5,
}
