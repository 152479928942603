import { AppState } from '@redux/types';
import { CountriesState } from '@redux/reducers/countries/types';

import { DEFAULT_COUNTRY } from '@shared/profile/constants';

export const _getCountries = (state: AppState): CountriesState[] => {
  return state.countries;
};

export function _getCountryById(state: AppState, countryId: number) {
  const countries = _getCountries(state);
  const country = countries.find(country => country.id === countryId);

  return country || DEFAULT_COUNTRY;
}
