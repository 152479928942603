import { AuthActionType } from '@redux/reducers/auth/enum';
import { AuthActions, AuthState } from '@redux/reducers/auth/types';

const defaultState: AuthState = {
  sessionId: null,
  bearer: null,
};

function authReducer(state = defaultState, action: AuthActions): AuthState {
  let auth;
  switch (action.type) {
    case AuthActionType.SetSessionId:
      auth = { ...state, sessionId: action.payload };
      break;
    case AuthActionType.SetBearer:
      auth = { ...state, ...action.payload };
      break;
    case AuthActionType.ClearAuth:
      auth = defaultState;
      break;
    default:
      auth = state;
  }

  return auth;
}

export default authReducer;
