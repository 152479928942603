import { ProfileWithFullName } from '@redux/reducers/profile/types';

import {
  AdobeAnalyticsPageInfo,
  AdobeAnalyticsUserInfo,
  RegionOptions,
} from '@utils/interfaces/adobe-analytics';
import { isDev, isNonProd } from '@utils/origin';
import isServer from '@utils/isServer';
import { readCookie } from '@utils/cookiesManager';

import { Currencies } from '@shared/enums/currency';
import { LANGUAGE } from '@shared/application/constants';
import { Order } from '@shared/interfaces/order';
import {
  PurchaseStatisticsProduct,
  TrackStatistics,
} from '@shared/interfaces/analytics';
import { SelectedSubscription } from '@shared/interfaces/selected-subscription';
import { _getProductType } from '@shared/basket/functions';

const ORGANIZATION_CODE = 'els';
const PRODUCT_CODE = 'ca';
const PAGE_NAME_INDENTIFIER = 'ca:web-store';
const PAGE_INIT = 'page_init';
const PRICING_PAGE = 'pricing';
const DEFAULT_USER_ID = 'unauthorized';

const AA_EVENTS = {
  newPage: 'newPage',
  buttonClick: 'buttonClick',
  productFeatureUsed: 'productFeatureUsed',
  purchaseComplete: 'purchaseComplete',
  addToBacket: 'addToBacket',
  config: {
    dev: 'dev',
    prod: 'prod',
  },
  licensePage: {
    eventName: {
      buyNow: 'BuyNow',
      comparePlans: 'ComparePlans',
    },
  },
  pricingPage: {
    eventName: {
      tiffStartToday: 'Tiff-Start-today',
      tiffTFT: 'Tiff-Take-the-Free-Trial',
      buyLicenseStartToday: 'BuyLicense-Start-today',
      buyNowStudent: 'BuyNow-StudentPlus',
      buyNowProfessional: 'BuyNow-Professional',
      upgradeProfessional: 'Upgrade-Professional',
      requestDemoInstitutional: 'RequestDemo-Institutional',
    },
  },
  subHeader: {
    eventName: {
      login: 'Login',
      logout: 'Logout',
    },
  },
  confirmationPage: {
    eventName: {
      setupGuid: 'SetupGuid',
      take2minute: 'Take2minute',
    },
  },
  checkout: {
    eventName: {
      creditCardPaymentMethod: 'CreditCardPaymentMethod',
      payPalPaymentMethod: 'PayPalPaymentMethod',
      paymentDetails: 'PaymentDetails',
      orderSummary: 'OrderSummary',
      placeOrder: 'PlaceOrder',
      aliPayPaymentMethod: 'AliPayPaymentMethod',
    },
  },
  accountPage: {
    eventName: {
      turnOnAutoRenewSwitch: 'Turn on auto-renew switch',
      turnOffAutoRenewSwitch: 'Turn off auto-renew switch',
      turnOffAutoRenewWarning:
        'Select "Turn off auto-renew" in the alert warning',
      getOfferWarning: 'Select "Get offer" in the alert warning',
    },
  },
  selectRegion: {
    eventName: {
      switchRegion: 'switch_region',
    },
  },
};

const CONTENT_TYPES = {
  CONTENT: 'content',
  SUBSCRIPTION: 'subscription',
};

const getContentType = (product: SelectedSubscription) => {
  const productType = _getProductType(product);

  switch (true) {
    case productType === 'subscription':
      return CONTENT_TYPES.SUBSCRIPTION;
    default:
      return CONTENT_TYPES.CONTENT;
  }
};

const getContentTypes = (products: SelectedSubscription[]) => {
  const contentTypes = Object.keys(CONTENT_TYPES).map<{
    contents: SelectedSubscription[];
    type: string;
  }>(type => ({
    contents: products.filter(
      product => getContentType(product) === CONTENT_TYPES[type]
    ),
    type: CONTENT_TYPES[type],
  }));

  return contentTypes;
};

const trackStatistics = (
  eventName: string,
  data: TrackStatistics | {} = {}
) => {
  if (
    !isServer() &&
    window['pageDataTracker'] &&
    JSON.parse(readCookie('showAnalyticsCookies'))
  ) {
    window['pageDataTracker'].trackEvent(eventName, data);
  }
};

const logButtonClickEvent = (name: string) => {
  trackStatistics(AA_EVENTS.buttonClick, {
    buttonType: name,
  });
};

class AdobeAnalytics {
  _userProfile: ProfileWithFullName = undefined;

  getPageInfo = (url: string): AdobeAnalyticsPageInfo => {
    let page = PRICING_PAGE;
    if (url) {
      if (url.length > 1) {
        page = url.substring(1).replace('/', ':');
      }
    }

    return {
      businessUnit: ORGANIZATION_CODE + ':' + PRODUCT_CODE,
      name: PAGE_NAME_INDENTIFIER + ':' + page,
      loadTimestamp: new Date().getTime().toString(),
      language: this._userProfile?.locale ?? LANGUAGE.English,
      productName: PRODUCT_CODE,
    };
  };

  getUserInfo = (): AdobeAnalyticsUserInfo => {
    let identifier =
      PRODUCT_CODE +
      ':' +
      (this._userProfile?.id?.toString() ?? DEFAULT_USER_ID);

    return {
      accountId: identifier,
      accountName: PRODUCT_CODE + ':-',
      userId: identifier,
      accessType: PAGE_NAME_INDENTIFIER,
    };
  };

  trackPageLoad = () => {
    if (window['pageDataTracker']) {
      window['pageDataTracker'].trackPageLoad({
        page: {
          environment: (isNonProd || isDev) ? AA_EVENTS.config.dev :
            AA_EVENTS.config.prod,
          name: PAGE_INIT,
        },
      });
    }
  };

  setUserProfile = (_userProfile: ProfileWithFullName) => {
    this._userProfile = _userProfile;
  };

  addPageView = (url: string) => {
    trackStatistics(AA_EVENTS.newPage, {
      page: this.getPageInfo(url),
      visitor: this.getUserInfo(),
    });
  };

  addBasketStatistics = (products: SelectedSubscription[], iso) => {
    const contentTypes = getContentTypes(products);
    contentTypes.forEach(item => {
      if (item.contents.length > 0) {
        const contentType = ':content_type=' + item.contents[0].slug;
        const bundle =
          ':bundle=' + products.map(product => product.bundle).join();
        let eventName = AA_EVENTS.addToBacket + bundle + contentType;
        logButtonClickEvent(eventName);
      }
    });
  };

  addPurchaseStatistics = (
    products: PurchaseStatisticsProduct[],
    order: Order,
    iso: Currencies,
    paymentSystem: string
  ) => {
    const contentTypes = getContentTypes(products);
    contentTypes.forEach(item => {
      if (item.contents.length > 0) {
        let total = 0;
        products.forEach(product => {
          total += product.price;
        });

        trackStatistics(AA_EVENTS.purchaseComplete, {
          content: [
            {
              price: total,
              quantity: products.length,
              type: item.type,
              id: products.map(product => product.id).join(),
              details:
                'bundle=' + products.map(product => product.bundle).join(),
            },
          ],
          order: {
            id: order.number,
            paymentMethod: paymentSystem,
          },
          page: {
            currencyCode: iso,
          },
        });
      }
    });
  };

  licensePage = {
    addBuyNow() {
      logButtonClickEvent(AA_EVENTS.licensePage.eventName.buyNow);
    },

    addComparePlans() {
      logButtonClickEvent(AA_EVENTS.licensePage.eventName.comparePlans);
    },
  };

  pricingPage = {
    addTiffStartToday() {
      logButtonClickEvent(AA_EVENTS.pricingPage.eventName.tiffStartToday);
    },

    addTiffTFT() {
      logButtonClickEvent(AA_EVENTS.pricingPage.eventName.tiffTFT);
    },

    addBuyNowStudent() {
      logButtonClickEvent(AA_EVENTS.pricingPage.eventName.buyNowStudent);
    },

    addRequestDemoInstitutional() {
      logButtonClickEvent(
        AA_EVENTS.pricingPage.eventName.requestDemoInstitutional
      );
    },

    addBuyNowProfessional() {
      logButtonClickEvent(AA_EVENTS.pricingPage.eventName.buyNowProfessional);
    },

    addUpgradeProfessional() {
      logButtonClickEvent(AA_EVENTS.pricingPage.eventName.upgradeProfessional);
    },
  };

  subHeader = {
    addLogin() {
      logButtonClickEvent(AA_EVENTS.subHeader.eventName.login);
    },

    addLogout() {
      logButtonClickEvent(AA_EVENTS.subHeader.eventName.logout);
    },
  };

  confirmationPage = {
    addSetupGuid() {
      logButtonClickEvent(AA_EVENTS.confirmationPage.eventName.setupGuid);
    },

    addTake2Minute() {
      logButtonClickEvent(AA_EVENTS.confirmationPage.eventName.take2minute);
    },
  };

  checkout = {
    addCreditCardPaymentMethod() {
      logButtonClickEvent(AA_EVENTS.checkout.eventName.creditCardPaymentMethod);
    },

    addPayPalPaymentMethod() {
      logButtonClickEvent(AA_EVENTS.checkout.eventName.payPalPaymentMethod);
    },

    addAliPayPaymentMethod() {
      logButtonClickEvent(AA_EVENTS.checkout.eventName.aliPayPaymentMethod);
    },

    addPaymentDetails() {
      logButtonClickEvent(AA_EVENTS.checkout.eventName.paymentDetails);
    },

    addPlaceOrder() {
      logButtonClickEvent(AA_EVENTS.checkout.eventName.placeOrder);
    },
  };

  accountPage = {
    turnOnAutoRenewSwitch() {
      logButtonClickEvent(
        AA_EVENTS.accountPage.eventName.turnOnAutoRenewSwitch
      );
    },

    turnOffAutoRenewSwitch() {
      logButtonClickEvent(
        AA_EVENTS.accountPage.eventName.turnOffAutoRenewSwitch
      );
    },

    turnOffAutoRenewWarning() {
      logButtonClickEvent(
        AA_EVENTS.accountPage.eventName.turnOffAutoRenewWarning
      );
    },

    getOfferWarning() {
      logButtonClickEvent(AA_EVENTS.accountPage.eventName.getOfferWarning);
    },
  };

  selectRegion = {
    switchRegion(options: RegionOptions) {
      const featureName =
        ':region=' +
        options.region +
        ':language=' +
        options.language +
        ':detectedRegion=' +
        options.detectedRegion +
        ':detectedLanguage=' +
        options.detectedLanguage;

      trackStatistics(AA_EVENTS.productFeatureUsed, {
        feature: {
          name: AA_EVENTS.selectRegion.eventName.switchRegion + featureName,
        },
      });
    },
  };
}

export default new AdobeAnalytics();
