import { AppState } from '@redux/types';
import { ApplicationsActionType } from '@redux/reducers/applications/enum';
import { _getAuth } from '@redux/reducers/auth/selectors';
import { _getBasket } from '@redux/reducers/basket/selectors';
import { _getQueryParams } from '@redux/reducers/queryParams/selectors';
import { logout } from '@redux/reducers/auth/action';
import { setBasket } from '@redux/reducers/basket/action';
import { setSale } from '@redux/reducers/sales/action';

import redirect from '@utils/redirect';

import Api from '@api/index';
import Cookies from 'universal-cookie';
import { Product } from '@shared/interfaces/product';
import { _addAppIdToProducts } from '@shared/application/functions';
import { _getAppById } from './selectors';
import {
  _getProductByBundle,
  _isPurchasedProducts,
} from '@shared/product/functions';
import { _sendError } from '@shared/logger/functions';
import { showAccessTokenWarning } from '../accessTokenWarning/action';

export const getApplications = () => ({
  type: ApplicationsActionType.Get,
});

export const setApplications = (applications: Product[]) => ({
  type: ApplicationsActionType.Set,
  payload: applications,
});

export const loadApplications =
  (language?: string) =>
  async (dispatch, getState: () => AppState): Promise<void> => {
    dispatch(getApplications());

    const state = getState();
    const queryParams = _getQueryParams(state);
    const auth = _getAuth(state);
    const basket = _getBasket(state);
    let applications;

    try {
      applications = await Api.getApplications(queryParams, auth, language);
    } catch (error) {
      _sendError('The API method getApplications() was failed.', error);

      if (error && error.response && error.response.status === 403) {
        return Promise.reject({ has403: true });
      }

      return Promise.reject({ has500: true });
    }

    const updatedApplications = _addAppIdToProducts(applications);
    updatedApplications.forEach(app => {
      dispatch(setSale(app));
    });
    dispatch(setApplications(updatedApplications));

    if (basket.length > 0) {
      const state = getState();
      const updatedBasket = basket.filter(bundle => {
        const product = _getProductByBundle(bundle, state);
        const isPurchased = product.is_purchased;
        const application = _getAppById(product.appId, state);
        const isPurchasedSubscription = _isPurchasedProducts(
          application.subscriptions
        );

        return !isPurchased && !isPurchasedSubscription;
      });

      dispatch(setBasket(updatedBasket));
    }
  };

export const errorHandling = (error, res?) => dispatch => {
  if (error && error.has403) {
    dispatch(logout());
    dispatch(showAccessTokenWarning());
    redirect(res);
  } else if (error && error.has500) {
    redirect(res, '/500');
  }
};
