import { B2C_LINKS } from '@shared/subscription/constants';
import { SubscriptionBuy } from '@shared/interfaces/subscription-buy';

export function _getSubscriptionIntervalDescription(interval: string) {
  switch (true) {
    case interval === 'year':
    default:
      // returns translation key (common)
      return 'annual';
  }
}

export const getStoreLink = ([sub]: Partial<SubscriptionBuy>[]):
  | string
  | undefined => {
  if (!sub) return;

  let subType = 'no-edu';

  const b2cSub = Object.values(B2C_LINKS);

  b2cSub.forEach(data => {
    if (subType !== 'no-edu') return;

    const _bundle = sub.bundle.toLowerCase().includes(data.bundle);
    const _store = sub.store.toLowerCase() === data.store;
    const _status = sub.status === data.status;

    switch (true) {
      case _bundle && _store && _status:
        subType = 'edu-webstore';
        break;
      case _bundle && _status:
        subType = 'edu-official';
        break;
    }
  });

  return B2C_LINKS[subType].link;
};
