export enum Currencies {
  EUR = 'EUR',
  GBP = 'GBP',
  CNY = 'CNY',
  BRL = 'BRL',
  MXN = 'MXN',
  CAD = 'CAD',
  INR = 'INR',
  AUD = 'AUD',
  JPY = 'JPY',
  USD = 'USD',
  Empty = '',
}
