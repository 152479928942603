import { ProfileAction, ProfileState } from '@redux/reducers/profile/types';
import { ProfileActionType } from '@redux/reducers/profile/enum';

import { isCN } from '@utils/origin';

import { Currencies } from '@shared/enums/currency';
import { DEFAULT_COUNTRY } from '@shared/profile/constants';

const initialState: Partial<ProfileState> = {
  country: DEFAULT_COUNTRY,
  subscriptionBuys: [],
  billingHistory: [],
  isNewCustomer: true,
  currencies: [isCN ? Currencies.CNY : Currencies.USD],
  last4: '',
  brand: '',
  month: null,
  year: null,
};

function profileReducer(
  state = initialState,
  action: ProfileAction
): ProfileState {
  let user;

  switch (action.type) {
    case ProfileActionType.Set:
      user = {
        ...state,
        id: action.payload.id,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        image: action.payload.image,
        kind: action.payload.kind,
        country: action.payload.updatedCountry ?? action.payload.country,
        email: action.payload.email,
        deletion: action.payload.deletion,
        verifiedEmail: action.payload.verify_email,
      };
      break;
    case ProfileActionType.Update:
      user = {
        ...state,
        ...action.payload,
      };
      break;
    case ProfileActionType.Clear:
      user = {
        ...initialState,
        country: state.country,
      };
      break;
    default:
      user = state;
  }

  return user;
}

export default profileReducer;
