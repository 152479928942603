import { AppState } from '@redux/types';

import { _getApps } from '@redux/reducers/applications/selectors';

import { APP_PRODUCT_FIELDS } from '../application/constants';
import { Bundle } from '@shared/enums/bundle';
import { Product } from '@shared/interfaces/product';
import { Subscription } from '@shared/interfaces/subscription';

export function _getProductByBundle(
  bundle: Bundle,
  state: AppState
): Subscription {
  const applications = _getApps(state);
  let product: Subscription = null;

  applications.forEach((app: Product) => {
    APP_PRODUCT_FIELDS.some(field => {
      const item: Subscription = app[field].find(
        (item: Subscription) =>
          item.bundle.toLowerCase() === bundle?.toLowerCase()
      );
      if (item) {
        product = item;

        return product;
      }

      return false;
    });
  });

  return product;
}

export function _getSeparatedNames(array: Product[] = [], separation = 'and') {
  return array.reduce((prevValue, currentValue, i) => {
    if (array.length === 1) {
      return `${prevValue} ${currentValue.name}`;
    }
    if (i + 1 === array.length) {
      return `${prevValue} ${separation} ${currentValue.name}`;
    }

    return `${prevValue} ${currentValue.name}${
      i + 2 === array.length ? '' : ','
    }`;
  }, '');
}

export function _isPurchasedProducts(array: Subscription[] = []) {
  return array.some(item => item.is_purchased);
}

export function _getActiveProducts(array: Product[] = []) {
  return array.filter(item => item.active);
}
