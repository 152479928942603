import { CORPSITE_URL } from '@utils/origin';
import { ROUTE } from '@utils/index';

export const SUBSCRIPTION_STATUS = {
  ACTIVE: 0,
  BILLING_RETRY: 5,
};

export const B2C_EDU = 'b2cedulicense';

export const OLD_BUNDLE = {
  STUDENT: 'com.3d4medical.ca.b2cstupluslicense',
  PRO: 'com.3d4medical.ca.b2cprolicense',
  INSTITUTIONAL: 'com.3d4medical.ca.institutional',
};

export const NEW_BUNDLE = {
  STUDENT: 'com.3d4medical.caweb.b2cstupluslicense',
  PRO: 'com.3d4medical.caweb.b2cprolicense',
  INSTITUTIONAL: 'com.3d4medical.ca.institutional',
};

export const B2C_LINKS = {
  'edu-webstore': {
    bundle: 'edulicense',
    store: '3d4medical',
    status: 0,
    link: `${ROUTE.APPLICATION.LICENSE(
      'complete-anatomy',
      'professional'
    )}?b2cedu=1`,
  },
  'edu-official': {
    bundle: 'edulicense',
    store: 'official',
    status: 0,
    link: `${CORPSITE_URL}/b2cedu-switch-guide`,
  },
  'no-edu': {
    bundle: 'fakeData',
    store: 'fakeData',
    status: -1,
    link: ROUTE.ACCOUNT.INDEX,
  },
};
