import { AppState } from '@redux/types';
import { SalesState } from '@redux/reducers/sales/types';

import { Sale } from '@shared/interfaces/sale';

export function _getSales(state: AppState, appId: number): Sale | SalesState {
  if (typeof appId !== 'undefined') {
    return state.sales[appId];
  }

  return state.sales;
}
