import { signOut } from 'next-auth/react';

import { AuthActionType } from '@redux/reducers/auth/enum';
import {
  changeUserInfo,
  clearProfile,
  createUserProfile,
  getUserProfile,
} from '@redux/reducers/profile/action';
import { setCountry } from '@redux/reducers/country/action';

import AnalyticsManager from '@utils/analyticsManager';

import { addUserDetailsCountly } from '@utils/analytics';

import { isCN } from '@utils/origin';
import { removeCancelRenewalData } from '@utils/localStorage';

import { clearBasket } from '../basket/action';
import { env } from 'next-runtime-env';
import { errorHandling, loadApplications } from '../applications/action';

export const setSessionId = (sessionId: string) => ({
  type: AuthActionType.SetSessionId,
  payload: sessionId,
});

export const setBearer = (bearer: string, expires: string) => ({
  type: AuthActionType.SetBearer,
  payload: { bearer, expires: new Date(expires) },
});

export const clearAuth = () => ({
  type: AuthActionType.ClearAuth,
});

export const fullLogout = () => dispatch => {
  isCN
    ? dispatch(changeUserInfo())
    : window.open(env('NEXT_PUBLIC_NEOID_ACCOUNT_URL'), '_blank', 'noopener,noreferrer');

  removeCancelRenewalData();
  dispatch(logout());
};

export const logout = () => dispatch => {
  AnalyticsManager.shared.subHeader.addLogout();
  AnalyticsManager.googleLegacy.subHeader.addLogout();

  signOut();

  dispatch(clearAuth());
  dispatch(clearProfile());
  dispatch(clearBasket());

  try {
    dispatch(loadApplications());
  } catch (error) {
    dispatch(errorHandling(error));
  }
};

export const login =
  (getApplications = true, authCallbackUrl?: string) =>
  async dispatch => {
    AnalyticsManager.shared.subHeader.addLogin();
    AnalyticsManager.googleLegacy.subHeader.addLogin();

    let userProfile;

    try {
      userProfile = await dispatch(getUserProfile(authCallbackUrl));
    } catch (error) {
      dispatch(errorHandling(error));

      return Promise.reject(error);
    }

    if (!userProfile) {
      return Promise.reject();
    }

    addUserDetailsCountly(userProfile);

    if (getApplications) {
      try {
        await Promise.all([
          dispatch(
            setCountry(userProfile.updatedCountry ?? userProfile.country)
          ),
          dispatch(loadApplications()),
        ]);
      } catch (error) {
        dispatch(errorHandling(error));

        return Promise.reject();
      }
    }
  };

export const signUp =
  (getApplications = true) =>
  async dispatch => {
    AnalyticsManager.shared.subHeader.addLogin();
    AnalyticsManager.googleLegacy.subHeader.addLogin();

    let userProfile;

    try {
      userProfile = await dispatch(createUserProfile());
    } catch (error) {
      dispatch(errorHandling(error));

      return Promise.reject(error);
    }

    if (!userProfile) {
      return Promise.reject();
    }

    addUserDetailsCountly(userProfile);

    if (getApplications) {
      try {
        await Promise.all([
          dispatch(
            setCountry(userProfile.updatedCountry ?? userProfile.country)
          ),
          dispatch(loadApplications()),
        ]);
      } catch (error) {
        dispatch(errorHandling(error));

        return Promise.reject();
      }
    }
  };
