import { CountriesState } from '@redux/reducers/countries/types';
import { CountryAction, CountryState } from '@redux/reducers/country/types';
import { CountryActionType } from '@redux/reducers/country/enum';
import countriesReducer from '@redux/reducers/countries/reducer';

const initialState: CountryState = {
  loading: false,
  userLocation: {} as CountriesState,
};

function countryReducer(
  state = initialState,
  action: CountryAction
): CountryState {
  switch (action.type) {
    case CountryActionType.LoadingCountry:
      return { ...state, loading: action.payload };
    case CountryActionType.UserLocation:
      return { ...state, userLocation: action.payload };
    default:
      return state;
  }
}

export default countryReducer;
