import Bowser from 'bowser';
import Router from 'next/router';

export const _isServicePage = (pathname = '') => {
  return [
    '/blank',
    '/500',
    '/access-token',
    '/paypal-cancel',
    '/paypal-blank',
    '/not-support',
    '/alipay',
  ].some(path => path === pathname);
};

export const _isIEBrowser = () => {
  const bowser = Bowser.getParser(window.navigator.userAgent);
  const browserName = bowser.getBrowserName();
  if (browserName === 'Internet Explorer') {
    Router.push('/not-support');

    return true;
  }

  return false;
};
