import { AppState } from '@redux/types';
import { AuthState } from '@redux/reducers/auth/types';

export function _isAuthorized(state: AppState): boolean {
  const auth = _getAuth(state);
  const isBearerExpired = new Date(auth.expires) < new Date();

  return auth.bearer && !isBearerExpired;
}

export function _getAuth(state: AppState): AuthState {
  return state.auth;
}
