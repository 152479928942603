import KeycloakProvider from 'next-auth/providers/keycloak';

import { getAuthProviderBaseURI } from '@utils/auth';
import { isCN } from '@utils/origin';

import { NextAuthOptions } from 'next-auth';

const getJWTPayload = (accessToken: string) => {
  const [, payload] = accessToken.split('.');

  return JSON.parse(Buffer.from(payload, 'base64').toString('utf-8'));
};

const getExpiresAt = (accessToken: string) => {
  const { exp } = getJWTPayload(accessToken);

  return exp * 1000;
};

export const authOptions = {
  providers: [
    !isCN
      ? KeycloakProvider({
          clientId: 'gme-completeanatomy-webstore',
          clientSecret: '',
          issuer: process.env.KEYCLOAK_ISSUER,
          client: {
            id_token_signed_response_alg: 'ES256',
          },
        })
      : {
          id: 'sso-oidc',
          name: 'SSO',
          wellKnown: `${process.env.BASE_URI}/protocol/openid-connect/.well-known/openid-configuration`,
          type: 'oauth',
          idToken: true,
          clientId: 'complete-anatomy',
          clientSecret: '',
          authorization: { params: { scope: 'openid' } },
          checks: ['pkce', 'state'],
          profile(profile) {
            return {
              id: profile.sub,
              name: profile.name ?? profile.preferred_username,
              email: profile.email,
              image: profile.picture,
            };
          },
        },
  ],
  callbacks: {
    session({ session, token, user, ...rest }) {
      if (token.accessToken) {
        session.accessToken = token.accessToken as string;
        session.tokenExpiresAt = new Date(
          (token.expiresAt as number) ||
            getExpiresAt(token.accessToken as string)
        ).toISOString();
      }

      if (token.profile) {
        session.auth0_id = (token.profile as { auth0_id: string }).auth0_id;
      }

      if (token.error) {
        session.error = token.error as string;
      }

      return session;
    },
    async jwt({ token, user, account, trigger, profile, ...rest }) {
      if (account) {
        token.accessToken = account.access_token;
        token.refreshToken = account.refresh_token;
        token.expiresAt = account.expires_at * 1000;
      }

      if (profile) {
        token.profile = profile;
      }

      const isExpired = Date.now() >= (token.expiresAt as number);

      if (trigger === 'update' || (!account && isExpired)) {
        const provider = getAuthProviderBaseURI();
        try {
          const response = await fetch(
            provider + '/protocol/openid-connect/token',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
              },
              body: new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: isCN
                  ? 'complete-anatomy'
                  : 'cs-completeanatomy-webstore',
                refresh_token: token.refreshToken as string,
              }),
            }
          );

          if (!response.ok) {
            throw response;
          }

          const data = await response.json();
          const profile = getJWTPayload(data.id_token);

          token.name = profile.name ?? profile.preferred_username;
          token.email = profile.email;
          token.picture = profile.picture;

          token.accessToken = data.access_token;
          token.refreshToken = data.refresh_token;
          token.expiresAt = data.expires_at * 1000;
        } catch (error) {
          console.error('Error refreshing access token', error);

          return { ...token, error: 'RefreshAccessTokenError' as const };
        }
      }

      return token;
    },
  },
  pages: {
    signIn: '/account',
  },
} as NextAuthOptions;
